<template>
    <v-row no-gutters class="d-block">
        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
            <v-card>
                <v-card-title>
                    <v-row>
                        <v-col cols="6">
                            <v-btn color="info" outlined @click="getCursos()" small>
                                <v-icon>refresh</v-icon>
                                Refrescar
                            </v-btn>
                        </v-col>
                        <v-col cols="6" class="pt-0">
                            <v-text-field
                                v-model="search"
                                prepend-icon="search"
                                label="Escribe para buscar"
                                single-line
                                hide-details
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <v-data-table
                        dense
                        class="elevation-3"
                        :headers="tableHeaders"
                        :items="cursos"
                        :search="search"
                        :loading="loading"
                        item-key="curso_id"
                        :footer-props=$store.state.footerPropsTable
                    >
                        <template v-slot:item="{ item }">
                            <tr>
                                <td class="text-left">{{ item.registro }}</td>
                                <td class="text-left">{{ item.curso }}</td>
                                <td class="text-left">{{ item.fecha_inicio | fechaESP }}</td>
                                <td class="text-left">{{ item.fecha_finalizacion | fechaESP }}</td>
                                <td class="text-left">{{ item.tutor }}</td>
                                <td class="text-left">
                                    <div class="text-center">
                                        <v-btn small outlined color="info"
                                               v-if="item.aprobado == true"
                                               @click="certificadoGenerate(item)">
                                            <v-icon>download</v-icon>
                                            Descargar Certificado
                                        </v-btn>
                                        <v-btn small outlined color="error" v-else
                                               @click="reprobado(item)">
                                            Reprobado
                                        </v-btn>
                                    </div>
                                </td>
                            </tr>
                        </template>
                        <template v-slot:no-data>
                            <v-alert dense type="warning" outlined class="text-center mt-3">
                                No existen registros.
                            </v-alert>
                        </template>
                        <template v-slot:no-results>
                            <v-alert dense type="warning" outlined class="text-center mt-3">
                                No se encontraron registros para la búsqueda "{{ search }}".
                            </v-alert>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="12">
            <v-dialog v-model="dialog" persistent transition="dialog-bottom-transition"
                    scrollable fullscreen>
                <v-card>
                    <v-toolbar dark color="info" dense >
                        <v-toolbar-title>Certificado Generado</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn icon dark @click="closePdfViewer">
                            <v-icon>close</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <iframe ref="objectPDF"
                            :src="url_certificado"
                            width="100%"
                            height="100%"
                            @load="iframePdfLoaded"
                            >
                    </iframe>

                </v-card>
            </v-dialog>

            <v-dialog
                v-model="showModalCertificado"
                persistent
                width="300">
                <v-card
                    color="info"
                    dark>
                    <v-card-text class="pt-5">
                        Cargando Certificado
                        <v-progress-linear
                            indeterminate
                            color="white"
                            class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-col>

    </v-row>

</template>

<script>
import Ls from "@/services/ls";
export default {
    name: "MisCursosLista",
    props: {
        // Si es requerido falso, que sea Default Vacio
        getParams: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            search: "",
            loading: false,
            cursos: [],
            error: false,
            showResult: false,
            result: '',
            dialog: false,
            showModalCertificado: false,
            url_certificado: null,
            tableHeaders: [
                {
                    text: 'Registro',
                    value: 'registro',
                    align: 'left',
                    sortable: true,
                },
                {
                    text: 'Curso',
                    value: 'curso',
                    align: 'left',
                    sortable: true,
                },

                {
                    text: 'Fecha inicio',
                    value: 'fecha_inicio',
                    align: 'left',
                    sortable: true,
                    filterable: false
                },
                {
                    text: 'Fecha fin',
                    value: 'fecha_finalizacion',
                    align: 'left',
                    sortable: true,
                    filterable: false
                },
                {
                    text: 'Tutor',
                    value: 'tutor',
                    align: 'left',
                    sortable: true,
                },
                {
                    text: 'Acciones',
                    align: 'center',
                    sortable: false,
                    filterable: false
                },

            ],
            filtros: {
                aprobado: this.getParams.aprobado,
                curso_id: Ls.get("curso_id")
            }
        }
    },

    methods: {
        getCursos() {
            this.loading = true;
            let params = {filtros: this.filtros};
            window.axios.get('/cursos/estudiantes', {params}).then(response => {
                if (response.data.success) {

                    this.cursos = response.data.data.filter((item) => {
                        return (item.aprobado == this.filtros.aprobado);
                    });

                } else if (response.data.success === false) {
                    this.$toast.error(response.data.message);
                }
            })
                .catch(error => {
                    console.log(error)
                    this.$toast.error("No se pudo listar datos.");
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        certificadoGenerate(curso) {
            //this.url_certificado = null
            //this.dialog = true;
            this.showModalCertificado = true;
            let params = btoa(JSON.stringify({
                curso_id: curso.curso_id
            }));
            //this.dialog = false;
            this.url_certificado = `${this.baseURLApi}/certificados/generate/${params}`;
            this.dialog = true;
            //window.open(this.url_certificado, '_blank');
            //this.loading = false;
            /*
            setTimeout(() => {
                this.showModalCertificado = false;
                this.dialog = true;
                }, 2000);
            */
            /*
            window.axios.get(this.url_certificado, {responseType: 'blob'}).then(response => {
                // Create a URL for the blob
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                const now = new Date();
                const year = now.getFullYear();
                const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                const day = String(now.getDate()).padStart(2, '0');
                const hours = String(now.getHours()).padStart(2, '0');
                const minutes = String(now.getMinutes()).padStart(2, '0');
                const seconds = String(now.getSeconds()).padStart(2, '0');                
                let filename = `Certificado_INFOP_Reg_No_${curso.registro}_${year}${month}${day}-${hours}${minutes}${seconds}.pdf`;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
                this.loading = false;
                this.showModalCertificado = false;
            })
            .catch(error => {
                console.log(error)
                this.loading = false;
                this.showModalCertificado = false;
                this.$toast.error("No se pudo descargar el PDF.");
            })
            .finally(() => {
                this.loading = false;
            })
            */
        },
        iframePdfLoaded() {
            console.log("Pdf loaded");
            this.showModalCertificado = false;
            this.dialog = true;
        },
        closePdfViewer() {
            this.url_certificado = null;
            setTimeout(() => {
                this.showModalCertificado = false;
                this.dialog = false;
                console.log('This message is displayed after 2000 milliseconds');
            }, 500);
        },
        reprobado(curso) {
            this.$toast.error(`Reprobaste con ${curso.nota} puntos.`);
        }

    },
    created() {
        this.getCursos();
    }
}
</script>

<style scoped>

</style>
